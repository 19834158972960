// Font weight extended

@each $font-weight, $value in $font-weight {
  .font-weight-#{$font-weight} {
    /* stylelint-disable-next-line declaration-no-important */
    font-weight: #{$value} !important;
  }
}

// Contextual colors

/* stylelint-disable declaration-no-important */
.text-black-25 { color: $black-25 !important; }
.text-black-75 { color: $black-75 !important; }
.text-white-25 { color: $white-25 !important; }
.text-white-75 { color: $white-75 !important; }
/* stylelint-enable */
