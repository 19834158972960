//
// Headings
//

h1,
.h1 { font-size: $h1-font-size-sm; }
h2,
.h2 { font-size: $h2-font-size-sm; }
h3,
.h3 { font-size: $h3-font-size-sm; }
h4,
.h4 { font-size: $h4-font-size-sm; }
h5,
.h5 { font-size: $h5-font-size-sm; }
h6,
.h6 { font-size: $h6-font-size-sm; }

@include media-breakpoint-up(md) {
  h1,
  .h1 { font-size: $h1-font-size; }
  h2,
  .h2 { font-size: $h2-font-size; }
  h3,
  .h3 { font-size: $h3-font-size; }
  h4,
  .h4 { font-size: $h4-font-size; }
  h5,
  .h5 { font-size: $h5-font-size; }
  h6,
  .h6 { font-size: $h6-font-size; }
}

// Type display classes
.display-1 {
  font-size: $display1-size-xs;
}
.display-2 {
  font-size: $display2-size-xs;
}
.display-3 {
  font-size: $display3-size-xs;
}
.display-4 {
  font-size: $display4-size-xs;
}
@include media-breakpoint-up(md) {
  .display-1 {
    font-size: $display1-size-md;
  }
  .display-2 {
    font-size: $display2-size-md;
  }
  .display-3 {
    font-size: $display3-size-md;
  }
  .display-4 {
    font-size: $display4-size-md;
  }
}
@include media-breakpoint-up(xl) {
  .display-1 {
    font-size: $display1-size-xl;
  }
  .display-2 {
    font-size: $display2-size-xl;
  }
  .display-3 {
    font-size: $display3-size-xl;
  }
  .display-4 {
    font-size: $display4-size-xl;
  }
}

//
// Blockquotes
//

.blockquote {
  margin-bottom: ($spacer * 2);
  font-size: $blockquote-font-size;
  font-style: italic;
  font-weight: $font-weight-500;
  line-height: $blockquote-line-height;
  color: $blockquote-color;
}
.blockquote-footer {
  margin-top: $blockquote-footer-margin;
  font-size: $blockquote-footer-font-size;
  font-style: normal;
  font-weight: $font-weight-base;

  &::before {
    position: relative;
    top: -4px;
    display: inline-block;
    width: 12px;
    height: 1px;
    margin-right: $blockquote-footer-margin;
    content: "";
    background-color: lighten($blockquote-color, 60%);
  }
}
.blockquote-light-1 {
  color: $white;

  .blockquote-footer {
    color: $white-50;

    &::before {
      background-color: $white-50;
    }
  }
}
.blockquote-light-2 {
  color: $white;

  .blockquote-footer {
    color: $white-75;

    &::before {
      background-color: $white-75;
    }
  }

  &.blockquote-3 {
    /* stylelint-disable-next-line declaration-no-important */
    border-color: $white !important;
  }
}
.blockquote-2 {
  @extend .blockquote;
  padding: .1875rem 1.5625rem .375rem;
  /* stylelint-disable declaration-no-important */
  border-color: rgba($black, .07) !important;
  border-width: ($border-width + 4) !important;

  &.blockquote-light-1 {
    border-color: rgba($white, .16) !important;
  }

  &.blockquote-light-2 {
    border-color: rgba($white, .5) !important;
  }
  /* stylelint-enable */
}
.blockquote-3 {
  @extend .blockquote;
  padding: 1.437rem 1.5625rem 1.625rem;
  background-color: $white;
  /* stylelint-disable */
  border-color: $main-color !important;
  border-width: ($border-width + 4) !important;
  /* stylelint-enable */

  &.blockquote-light-1,
  &.blockquote-light-2 {
    background-color: lighten($black, 6.5%);

    .blockquote-footer {
      color: $white-50;

      &::before {
        background-color: $white-50;
      }
    }
  }
}
