// Base class

.list-group {
  padding: $list-group-padding-y 0;

  @if $enable-rounded-theming {
    border-radius: $list-group-border-radius;
  } @else {
    border-radius: 0;
  }
}

// Interactive list items

.list-group-item-action {
  @include hover-focus {
    background-color: transparent;

    &::before {
      opacity: 1;
    }
  }
}

// Individual list items

.list-group-item {
  margin-bottom: 0;
  border: none;

  &:first-child,
  &:last-child {
    border-radius: 0;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: $list-group-active-width;
    height: 100%;
    content: "";
    background-color: $list-group-active-bg;
    opacity: 0;
    @include transition-prefix("all", $transition-duration-base, $transition-timing-base);
  }

  &.disabled,
  &:disabled {
    opacity: $disabled-opacity;

    &::before {
      display: none;
    }
  }

  &.active {
    color: $body-color-base;
    background-color: transparent;
    border-color: transparent;

    &::before {
      opacity: 1;
    }
  }
}

// Contextual variants

@each $color, $value in $theme-colors {
  @if ($color == "light") {
    @include list-group-item-variant($color, $value, $viridian-100, $list-group-active-bg);
  } @else {
    @include list-group-item-variant($color, $value, $white, rgba($white, .6));
  }
}
