.page-link {
  min-width: $pagination-min-width;
  font-size: $pagination-font-size;
  text-align: center;
  background-color: transparent;
  border: none;

  @if $enable-rounded-theming {
    border-radius: $pagination-border-radius;
  } @else {
    border-radius: 0;
  }

  @include transition-prefix("all", $transition-duration-base, $transition-timing-base);

  &:focus {
    background-color: $pagination-focus-bg;
    outline: 0;
    box-shadow: none;
  }
}

.page-item {
  margin: $pagination-margin;

  &:first-child {
    .page-link {
      @if $enable-rounded-theming {
        border-top-left-radius: $pagination-border-radius;
        border-bottom-left-radius: $pagination-border-radius;
      } @else {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  &:last-child {
    .page-link {
      @if $enable-rounded-theming {
        border-top-right-radius: $pagination-border-radius;
        border-bottom-right-radius: $pagination-border-radius;
      } @else {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.active .page-link {
    @if $enable-shadows-theming {
      box-shadow: $pagination-box-shadow;
    } @else {
      box-shadow: none;
    }
  }

  &.disabled .page-link {
    background-color: transparent;
    opacity: $disabled-opacity;
  }
}

// Pagination round
.pagination-round {
  .page-link {
    min-width: $pagination-min-width + .0625;
    border-radius: $pagnation-round-border-radius;
  }

  .page-item {
    &:first-child {
      .page-link {
        @include border-left-radius($pagnation-round-border-radius);
      }
    }
    &:last-child {
      .page-link {
        @include border-right-radius($pagnation-round-border-radius);
      }
    }
  }
}

//
// Sizing
//

.pagination-lg {
  @include pagination-size-custom($pagination-min-width-lg, $pagination-margin-lg, $pagination-padding-y-lg, $pagination-padding-x-lg, $pagination-font-size-lg, $line-height-lg, $pagination-border-radius, $pagnation-round-border-radius-lg, $pagination-box-shadow-lg);
}

.pagination-sm {
  @include pagination-size-custom($pagination-min-width-sm, $pagination-margin-sm, $pagination-padding-y-sm, $pagination-padding-x-sm, $pagination-font-size-sm, $line-height-sm, $pagination-border-radius, $pagnation-round-border-radius-sm, $pagination-box-shadow);
}

// Pagination light 1
//
// Use for container background dark
.pagination-light-1 {

  .page-link {
    color: $pagination-color-light-1;

    &:hover {
      background-color: $pagination-hover-bg-light-1;
    }

    &:focus {
      color: $pagination-focus-color-light-1;
      background-color: $pagination-focus-bg-light-1;
    }
  }

  .page-item {

    &.active .page-link {
      background-color: $pagination-active-bg;
    }

    &.disabled .page-link {
      color: $pagination-disabled-color-light-1;
    }
  }
}

// Pagination light 2
//
// Use for container background color
.pagination-light-2 {

  .page-link {
    color: $pagination-color-light-2;

    &:hover {
      background-color: $pagination-hover-bg-light-2;
    }

    &:focus {
      background-color: $pagination-focus-bg-light-2;
    }
  }

  .page-item {

    &.active .page-link {
      color: $pagination-active-bg;
      background-color: $pagination-active-bg-light-2;

      @if $enable-shadows-theming {
        box-shadow: $pagination-box-shadow-light-2;
      } @else {
        box-shadow: none;
      }
    }

    &.disabled .page-link {
      color: $pagination-color-light-2;
    }
  }

  &.pagination-lg {
    .active .page-link {
      @if $enable-shadows-theming {
        box-shadow: $pagination-box-shadow-lg-light-2;
      } @else {
        box-shadow: none;
      }
    }
  }
}
